import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import loginImg from '../assets/svg/loginBg.svg';
import axios from 'axios';
import { baseApi } from '../utils/constant';
import { Helmet } from 'react-helmet-async';
// import { jwtDecode } from 'jwt-decode';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const Auth = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${baseApi}/users/login`, {
                email: email,
                password: password,
            });

            navigate('/admin/dashboard');
        } catch (error) {
            console.error(error);
            if (error.response) {
                setMessage(error.response.data.message);
            }
        }
    };

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };
    return (
        <div className="h-screen w-screen bg-[#F5F5F5]">
            <Helmet>
                <title>
                    Supplier Helper - Connecting Workers and Employers
                </title>
                <meta
                    name="description"
                    content="Welcome to Supplier Helper, where we connect employers and workers."
                />
                <meta
                    name="copyright"
                    content="© 2024 Supplier Helper. All rights reserved."
                />
                <meta name="author" content="Supplier Helper Team" />
            </Helmet>
            <div className="flex flex-wrap-reverse justify-center lg:flex lg:flex-nowrap">
                <div className="content-center text-[#343434] lg:h-screen lg:flex-nowrap lg:px-[62px] lg:py-[32px]">
                    <div className="mb-[40px] mt-[24px] lg:mb-[32px] lg:w-[422px]">
                        <p className="text-headline-lg lg:mb-[12px]">
                            Welcome Back 👋
                        </p>
                        <p className="text-subtitle-lg">
                            Today is a new day. It's your day. You shape it.{' '}
                            <br />
                            Sign in to start managing your projects.
                        </p>
                    </div>
                    <div className="lg:w-[422px]">
                        <p>{message}</p>
                        <form onSubmit={Auth} className="">
                            <div className="mb-[16px] lg:mb-[24px]">
                                <label
                                    for="email"
                                    className="block text-subtitle-lg font-medium leading-6 text-gray-900">
                                    Email address
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autocomplete="email"
                                    placeholder="your@email.com"
                                    required
                                    className="sm:text-sm mt-[8px] block w-full rounded-[8px] border-0 bg-transparent p-[12px] text-gray-900 shadow-sm ring-1 ring-inset ring-[#F3B129] placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#F3B129] sm:leading-6 lg:rounded-[12px]"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div className="mb-[16px] lg:mb-[24px]">
                                <label
                                    for="password"
                                    className="block text-subtitle-lg font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="relative">
                                    <input
                                        id="password"
                                        name="password"
                                        type={
                                            isPasswordVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        placeholder="Enter your password"
                                        autoComplete="current-password"
                                        required
                                        className="sm:text-sm mt-[8px] block w-full rounded-[8px] border-0 bg-transparent p-[12px] text-gray-900 shadow-sm ring-1 ring-inset ring-[#F3B129] placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#F3B129] sm:leading-6 lg:rounded-[12px]"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600 hover:text-[#F3B129]"
                                        onClick={togglePasswordVisibility}>
                                        {isPasswordVisible ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="h-5 w-5">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="h-5 w-5">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                            </svg>
                                        )}
                                    </button>
                                </div>
                                <div className="mt-[16px] flex lg:mt-[24px]">
                                    <a
                                        href="#"
                                        class="font-medium text-[#4F46E5] hover:underline">
                                        Forgot Password?
                                    </a>
                                </div>
                                <div className="mt-[16px] text-[#F5F5F5] lg:mt-[24px]">
                                    <button className="w-full rounded-[8px] bg-[#F3B129] py-[16px] text-center text-subtitle-lg font-bold hover:bg-[#d49c24] hover:shadow-sm lg:rounded-[12px] lg:text-subtitle-lg lg:font-bold">
                                        Sign in
                                    </button>
                                </div>
                                <div className="my-[16px] h-[2px] w-full bg-[#CFDFE2] lg:my-[24px]"></div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="flex w-full items-center justify-center bg-[#F5DBC4] p-[8px] lg:h-screen lg:rounded-l-[24px]">
                    <img
                        src={loginImg}
                        alt=""
                        className="w-[180px] object-cover lg:w-[500px]"
                    />
                </div>
            </div>
        </div>
    );
}

export default Login;
