import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import React from 'react';
import Index from './pages/index';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Helpers from './pages/helpers';
import Employes from './pages/employes';
import AddHelpers from './pages/form/addHelpers';
import EditHelpers from './pages/form/editHelpers';
import AddEmployer from './pages/form/addEmployer';
import EditEmployer from './pages/form/editEmployer';
import { Navigate } from 'react-router-dom';
import NavSideAdmin from './components/NavSideAdmin';
import Debug from './pages/debug';
import HelperList from './pages/HelperList';
import HelperDetail from './pages/HelperDetail';
import BookHelper from './pages/BookHelper';
import EmployerList from './pages/EmployerList';
import ApplyJob from './pages/ApplyJob';
import Blog from './pages/Blog';
import Addblog from './pages/form/addBlog';
import EditBlog from './pages/form/editBlog';
import BlogList from './pages/BlogList';
import BlogDetail from './pages/BlogDetail';

function App() {
    return (
        <HelmetProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Index />} />
                    <Route path="/helpersList" element={<HelperList />} />
                    <Route
                        path="/helpersList/detail/:id"
                        element={<HelperDetail />}
                    />
                    <Route path="/bookHelper/:id" element={<BookHelper />} />
                    <Route path="/employerList" element={<EmployerList />} />
                    <Route
                        path="/employer/ApplyJob/:id"
                        element={<ApplyJob />}
                    />
                    <Route path="/blogList" element={<BlogList />} />
                    <Route
                        path="/blogList/detail/:id"
                        element={<BlogDetail />}
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/admin" element={<NavSideAdmin />}>
                        <Route
                            path="/admin"
                            element={<Navigate to="dashboard" />}
                        />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="helpers" element={<Helpers />} />
                        <Route path="helpers/add" element={<AddHelpers />} />
                        <Route
                            path="helpers/edit/:id"
                            element={<EditHelpers />}
                        />
                        <Route path="employer" element={<Employes />} />
                        <Route path="employer/add" element={<AddEmployer />} />
                        <Route
                            path="employer/edit/:id"
                            element={<EditEmployer />}
                        />
                        <Route path="blogs" element={<Blog />} />
                        <Route path="blogs/add" element={<Addblog />} />
                        <Route path="blogs/edit/:id" element={<EditBlog />} />
                    </Route>
                    <Route path="/debug" element={<Debug />} />
                </Routes>
            </Router>
        </HelmetProvider>
    );
}

export default App;
