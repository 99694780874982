import React, { useState, useEffect } from 'react';

//Package
import axios from 'axios';
import { baseApi } from '../utils/constant';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import debounce from 'lodash.debounce';

// Icon
import {
    IoPeople,
    IoBusiness,
    IoBody,
    IoNewspaper,
    IoSearch,
} from 'react-icons/io5';

function Dashboard() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [users, setUsers] = useState([]);
    const [helpers, setHelpers] = useState([]);
    const [totalHelpers, setTotalHelpers] = useState([]);
    const [totalEmploys, setTotalEmploys] = useState([]);
    const [totalBlogs, setTotalBlogs] = useState([]);
    const [expired, setExpired] = useState('');
    const [page, setPage] = useState();
    const [limit, setLimit] = useState(5);
    const [pages, setPages] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [query, setQuery] = useState('');
    const [rows, setRows] = useState(0);
    const [msg, setMsg] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUsers();
        getHelpers();
        getHelpersTotal();
        getEmploysTotal();
        getTotalBlogs();
    }, [page, keyword, limit]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseApi}/users/token`);
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            console.log(decoded);
            setUsername(decoded.username);
            setEmail(decoded.email);
            setExpired(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate('/login');
            }
        }
    };

    const axiosJwt = axios.create();

    axiosJwt.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expired < currentDate.getTime() / 1000) {
                const response = await axios.get(`${baseApi}/users/token`);
                config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                setToken(response.data.accessToken);
                const decoded = jwtDecode(response.data.accessToken);
                setUsername(decoded.username);
                setEmail(decoded.email);
                setExpired(decoded.exp);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    const getUsers = async () => {
        try {
            const response = await axiosJwt.get(`${baseApi}/users/getUsers`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUsers(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getHelpers = async () => {
        try {
            const response = await axiosJwt.get(
                `${baseApi}/helper/helpersList?search_query=${keyword}&page=${page}&limit=${limit}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            setHelpers(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);

            if (response.data.result.length === 0) {
                setMsg('Nama yang Anda cari tidak ditemukan.');
            } else {
                setMsg('');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setMsg('Terjadi kesalahan saat mengambil data.');
        }
    };

    const getHelpersTotal = async () => {
        try {
            const response = await axiosJwt.get(`${baseApi}/helper/total`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTotalHelpers(response.data.total);
        } catch (error) {}
    };

    const getEmploysTotal = async () => {
        try {
            const response = await axiosJwt.get(
                `${baseApi}/employer/employersTotal`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            setTotalEmploys(response.data.total);
        } catch (error) {}
    };

    const getTotalBlogs = async () => {
        try {
            const response = await axiosJwt.get(`${baseApi}/blog/blogsTotal`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTotalBlogs(response.data.total);
        } catch (error) {}
    };

    const handleSearch = debounce((value) => {
        setPage(0);
        setKeyword(value);
    }, 500);

    const handleChange = (e) => {
        setQuery(e.target.value);
        handleSearch(e.target.value);
    };

    const handleItemsPerPageChange = (e) => {
        setLimit(parseInt(e.target.value));
        setPage(0);
    };

    const changePage = ({ selected }) => {
        setPage(selected);
    };

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birthDateObj = new Date(birthDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDifference = today.getMonth() - birthDateObj.getMonth();

        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
        ) {
            age--;
        }

        return age;
    };

    return (
        <div className="mt-[70px] p-[32px]">
            <div className="overscroll-contain">
                <h1 className="text-headline-md text-white">Dashboard</h1>
                <div className="my-[32px] flex flex-row flex-wrap gap-[18px] lg:flex-nowrap lg:gap-[32px]">
                    <div className="flex w-full justify-between rounded-lg bg-[#202022] p-[16px] shadow-cardShadow">
                        <div>
                            <p className="text-subtitle-lg text-white">
                                Total User
                            </p>
                            <h1 className="mt-[16px] text-headline-md text-white">
                                1
                            </h1>
                        </div>
                        <div className="content-center rounded-lg bg-[#8280FF] px-[14px] py-[18px]">
                            <IoPeople size={32} className="fill-white" />
                        </div>
                    </div>

                    <div className="flex w-full justify-between rounded-lg bg-[#202022] p-[16px] shadow-cardShadow">
                        <div>
                            <p className="text-subtitle-lg text-white">
                                Total Helper
                            </p>
                            <h1 className="mt-[16px] text-headline-md text-white">
                                {totalHelpers}
                            </h1>
                        </div>
                        <div className="content-center rounded-lg bg-[#FEC53D] px-[14px] py-[18px]">
                            <IoBody size={32} className="fill-white" />
                        </div>
                    </div>

                    <div className="flex w-full justify-between rounded-lg bg-[#202022] p-[16px] shadow-cardShadow">
                        <div>
                            <p className="text-subtitle-lg text-white">
                                Total Employer
                            </p>
                            <h1 className="mt-[16px] text-headline-md text-white">
                                {totalEmploys}
                            </h1>
                        </div>
                        <div className="content-center rounded-lg bg-[#4AD991] px-[14px] py-[18px]">
                            <IoBusiness size={32} className="fill-white" />
                        </div>
                    </div>

                    <div className="flex w-full justify-between rounded-lg bg-[#202022] p-[16px] shadow-cardShadow">
                        <div>
                            <p className="text-subtitle-lg text-white">
                                Total Blog
                            </p>
                            <h1 className="mt-[16px] text-headline-md text-white">
                                {totalBlogs}
                            </h1>
                        </div>
                        <div className="content-center rounded-lg bg-[#FF9066] px-[14px] py-[18px]">
                            <IoNewspaper size={32} className="fill-white" />
                        </div>
                    </div>
                </div>

                {/* Tabel Helpers */}
                <div className="rounded-lg bg-[#202022] p-4 shadow-cardShadow">
                    <span className="text-body-lg text-white">
                        Daftar Helper
                    </span>

                    <div className="my-2 flex-row lg:flex lg:justify-between">
                        <select
                            value={limit}
                            onChange={handleItemsPerPageChange}
                            className="rounded-lg border border-[#F3B129] bg-[#202022] p-2 text-white">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                        </select>

                        <div className="flex h-[40px] w-[30%] items-center rounded-lg border border-[#F3B129] bg-[#202022] p-[6px]">
                            <IoSearch
                                className="mx-1 text-gray-400"
                                size={24}
                            />
                            <input
                                type="text"
                                placeholder="Cari..."
                                value={query}
                                onChange={handleChange}
                                className="ml-2 w-full border-none bg-[#202022] text-white placeholder-gray-400 outline-none"
                            />
                        </div>
                    </div>

                    <div className="my-4 overflow-x-auto rounded-lg border border-[#515151]">
                        <table className="table w-full space-y-6 rounded-t-lg p-[2px] text-white">
                            <thead>
                                <tr className="border-b border-[#515151] py-2 text-subtitle-sm text-white">
                                    <th className="w-auto p-3 text-center">
                                        No
                                    </th>
                                    <th className="w-[15%] p-3 text-left">
                                        Nama
                                    </th>
                                    <th className="p-3 text-left">Umur</th>
                                    <th className="w-[5%] p-3 text-left">
                                        TB/BB
                                    </th>
                                    <th className="p-3 text-left">
                                        Kemampuan Bahasa
                                    </th>
                                    <th className="p-3 text-left">
                                        Kemampuan Masak
                                    </th>
                                    <th className="p-3 text-left">
                                        Pengalaman
                                    </th>

                                    <th className="w-[10%] px-4 py-2 text-white">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {helpers.length > 0 ? (
                                    helpers.map((helper, index) => (
                                        <tr
                                            key={helper.id}
                                            className="border-t border-[#515151] font-light">
                                            <td className="px-4 py-2 text-white">
                                                {(page - 0) * limit + index + 1}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {helper.nama}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {calculateAge(
                                                    helper.tanggal_lahir,
                                                )}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {helper.tinggi_badan}/
                                                {helper.berat_badan}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {helper.kemampuan_bahasa}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {helper.kemampuan_masak}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {helper.pengalaman}
                                            </td>
                                            <td className="px-4 py-2 text-center capitalize text-white">
                                                <p
                                                    className={`w-fit rounded-full px-[12px] py-[4px] ${
                                                        helper.status ===
                                                        'available'
                                                            ? 'bg-green-700'
                                                            : helper.status ===
                                                                'unpublished'
                                                              ? 'bg-yellow-500'
                                                              : helper.status ===
                                                                  'sold out'
                                                                ? 'bg-red-500'
                                                                : 'bg-gray-300' // Warna default jika status tidak dikenal
                                                    }`}>
                                                    {helper.status}
                                                </p>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="3"
                                            className="px-4 py-2 text-center text-white">
                                            {msg || 'Tidak ada data'}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-[32px] flex-row lg:flex lg:items-center lg:justify-between">
                        <div className="w-auto text-white">
                            Menampilkan {helpers.length} dari {rows} data
                        </div>
                        <div className="">
                            <ReactPaginate
                                className="flex gap-x-2 rounded-md border border-[#f3b129] p-2"
                                previousLabel="<"
                                nextLabel=">"
                                breakLabel="..."
                                pageCount={pages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={changePage}
                                containerClassName="flex space-x-2"
                                pageClassName="px-4 py-2 rounded-lg cursor-pointer hover:bg-[#F3B129] hover:text-white"
                                previousClassName="px-4 py-2 rounded-lg cursor-pointer"
                                nextClassName="px-4 py-2 rounded-lg cursor-pointer"
                                activeClassName="bg-[#F3B129] text-white"
                                disabledClassName="text-gray-500 cursor-not-allowed"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
