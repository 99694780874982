import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Iklan from '../components/Iklan';
import About from '../components/About';
import Services from '../components/Services';
import Howit from '../components/Howit';
import Testimonial from '../components/Testimonial';
import Blog from '../components/Blog';
import FAQs from '../components/FAQs';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import LogoWa from '../assets/svg/whatsapp-social-media-svgrepo-com.svg';
import { useTranslation } from 'react-i18next';

function Index() {
    // State untuk modal dan kategori pertanyaan
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const { t } = useTranslation(); // Menggunakan useTranslation hook

    // Daftar pertanyaan untuk Helper dan Employer
    const questionsHelper = [
        t('questions.questionsHelper.quest1'),
        t('questions.questionsHelper.quest2'),
        t('questions.questionsHelper.quest3'),
    ];

    const questionsEmployer = [
        t('questions.questionsEmployer.quest1'),
        t('questions.questionsEmployer.quest2'),
        t('questions.questionsEmployer.quest3'),
    ];

    // Fungsi untuk membuka modal
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Fungsi untuk memilih pertanyaan dan mengarahkan ke WhatsApp
    const handleQuestionClick = (role, question) => {
        setSelectedQuestion(question);

        // Tentukan nomor WhatsApp berdasarkan peran (Helper atau Employer)
        let phoneNumber = '';
        if (role === 'Helper') {
            phoneNumber = '6288227565264'; // Nomor untuk Helper
        } else if (role === 'Employer') {
            phoneNumber = '6592416495'; // Nomor untuk Employer
        }

        const whatsappMessage = encodeURIComponent(
            `${t('questions.whatsappMessage')} ${question}`,
        );
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${whatsappMessage}`;
        window.open(whatsappUrl, '_blank');
        setIsModalOpen(false); // Tutup modal setelah pertanyaan diklik
    };
    return (
        <div>
            <Helmet>
                <title>
                    Supplier Helper - Connecting Workers and Employers
                </title>
                <meta
                    name="description"
                    content="Welcome to Supplier Helper, where we connect employers and workers."
                />
                <meta
                    name="copyright"
                    content="© 2024 Supplier Helper. All rights reserved."
                />
                <meta name="author" content="Supplier Helper Team" />
            </Helmet>
            {/* <div className="fixed left-[80%] top-[90%] z-50 rounded-full bg-[#25D366] lg:left-[85%] lg:top-[86%]">
                <a
                    href="https://wa.me/6288227565264?text=Halo%20Admin,%20saya%20membutuhkan%20bantuan%20terkait%20informasi%20di%20Supplier%20Helper.%20Saya%20bingung%20mengenai%20proses%20pendaftaran%20dan%20layanan%20yang%20Anda%20tawarkan.%20Mohon%20bimbingannya.%20Terima%20kasih!"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="flex items-center gap-2 p-4 lg:px-4 lg:py-2">
                    <div>
                        <img
                            src={LogoWa}
                            alt="Whatsapp"
                            loading="lazy"
                            decoding="async"
                            width={25}
                        />
                    </div>
                    <p className="hidden text-button-lg text-white lg:block">
                        Whatsapp
                    </p>
                </a>
            </div> */}

            {/* Tombol WhatsApp */}
            <div className="fixed left-[80%] top-[90%] z-50 rounded-full bg-[#25D366] lg:left-[85%] lg:top-[86%]">
                <button
                    onClick={openModal}
                    className="flex items-center gap-2 p-4 lg:px-4 lg:py-2">
                    <img
                        src={LogoWa}
                        alt="Whatsapp"
                        loading="lazy"
                        decoding="async"
                        width={25}
                    />
                    <p className="hidden text-button-lg text-white lg:block">
                        Whatsapp
                    </p>
                </button>
            </div>

            {/* Modal Pertanyaan */}
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="rounded-md bg-white p-6 shadow-lg">
                        <div className="flex gap-4">
                            {/* Pertanyaan untuk Helper */}
                            <div>
                                <h3 className="mb-2 font-semibold">Helper</h3>
                                <ul>
                                    {questionsHelper.map((question, index) => (
                                        <li key={index}>
                                            <button
                                                className="mb-2 rounded bg-green-500 p-2 text-white hover:bg-green-600"
                                                onClick={() =>
                                                    handleQuestionClick(
                                                        'Helper',
                                                        question,
                                                    )
                                                }>
                                                {question}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Pertanyaan untuk Employer */}
                            <div>
                                <h3 className="mb-2 font-semibold">Employer</h3>
                                <ul>
                                    {questionsEmployer.map(
                                        (question, index) => (
                                            <li key={index}>
                                                <button
                                                    className="mb-2 rounded bg-blue-500 p-2 text-white hover:bg-blue-600"
                                                    onClick={() =>
                                                        handleQuestionClick(
                                                            'Employer',
                                                            question,
                                                        )
                                                    }>
                                                    {question}
                                                </button>
                                            </li>
                                        ),
                                    )}
                                </ul>
                            </div>
                        </div>

                        <button
                            className="mt-4 rounded bg-red-500 p-2 text-white hover:bg-red-600"
                            onClick={() => setIsModalOpen(false)}>
                            {t('questions.close')}
                        </button>
                    </div>
                </div>
            )}

            <Navbar />
            <Hero />
            <Iklan />
            <Services />
            <Howit />
            <About />
            <Testimonial />
            <Blog />
            <FAQs />
            <ContactUs />
            <Footer />
        </div>
    );
}

export default Index;
