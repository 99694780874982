import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { IoAddCircle } from 'react-icons/io5';
import { IoSearch } from 'react-icons/io5';
import { FaFilter, FaRegEdit, FaEye, FaRegTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import debounce from 'lodash.debounce';
import { baseApi } from '../utils/constant';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import { useLocation } from 'react-router-dom';
// import fotoProfile from '../assets/default_profile.png';

function Employes() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [employers, setEmployers] = useState([]);
    const [token, setToken] = useState('');
    const [page, setPage] = useState();
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [query, setQuery] = useState('');
    const [msg, setMsg] = useState('');
    const [expired, setExpired] = useState('');
    const navigate = useNavigate();

    const [helpers, setHelpers] = useState([]);
    const [sortOption, setSortOption] = useState('createdAt'); // State untuk menyimpan opsi sort

    // const [file, setFile] = useState('');
    // const [preview, setPreview] = useState('');

    // const loadImage = (e) => {
    //     const image = e.target.files[0];
    //     setFile(image);
    //     setPreview(URL.createObjectURL(image));
    // };

    useEffect(() => {
        refreshToken();
        getEmployers();
    }, [page, keyword, limit]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseApi}/users/token`);
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpired(decoded.exp);
        } catch (error) {
            navigate('/login');
        }
    };

    const axiosJwt = axios.create();

    axiosJwt.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expired < currentDate.getTime() / 1000) {
                const response = await axios.get(`${baseApi}/users/token`);
                config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                setToken(response.data.accessToken);
                const decoded = jwtDecode(response.data.accessToken);
                setExpired(decoded.exp);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    const getEmployers = async () => {
        try {
            const response = await axiosJwt.get(
                `${baseApi}/employer/employersList?search_query=${keyword}&page=${page}&limit=${limit}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            setEmployers(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);

            if (response.data.result.length === 0) {
                setMsg('Data yang Anda cari tidak ditemukan.');
            } else {
                setMsg('');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setMsg('Terjadi kesalahan saat mengambil data.');
        }
    };

    // Function untuk delete helper by ID
    const handleDeleteClick = (id) => {
        Swal.fire({
            title: 'Apakah Anda yakin?',
            text: 'Data yang dihapus tidak dapat dikembalikan!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ya, hapus!',
            cancelButtonText: 'Batal',
            customClass: {
                popup: 'custom-popup',
            },
            iconColor: '#B24400',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id);
            }
        });
    };

    const handleDelete = async (id) => {
        try {
            const response = await axiosJwt.delete(
                `${baseApi}/employer/employers/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            // Update state setelah delete
            getEmployers();

            // Tampilkan toast untuk sukses
            Swal.fire({
                icon: 'success',
                title: 'Data berhasil dihapus.',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                customClass: {
                    popup: 'custom-swal', // Menambahkan kelas kustom
                },
                background: 'transparent', // Agar background default tidak mengganggu
                iconColor: '#409900', // Mengubah warna ikon
            });
        } catch (error) {
            console.log(error);

            Swal.fire({
                icon: 'error',
                title: 'Terjadi kesalahan saat menghapus data.',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                customClass: {
                    popup: 'custom-swal',
                },
            });
        }
    };

    const handleViewClick = (helper) => {
        const MySwal = withReactContent(Swal);

        MySwal.fire({
            title: '',
            html: `
            <div class="lg:text-left justify-center">
                <div class="flex lg:flex-row flex-col lg:text-left  items-center">
                    <div class=" ml-0 w-[70%] flex-col flex-wrap content-center lg:-mt-0 mt-4">
                        <p class="text-headline-md font-bold uppercase">${helper.nama}</p>
                        <p class="whitespace-normal text-body-base font-light">Lowongan : ${helper.pekerjaan}</p>
                        <p class="whitespace-normal text-body-base font-light">${helper.alamat}</p>
                    </div>
                </div>
                <div class="mt-4 flex flex-col lg:flex-row flex-wrap lg:flex-nowrap lg:text-left text-center capitalize  text-[#BFBFBF] lg:justify-between">
                    <div>
                        <p class="text-body-lg text-white">Tipe Rumah</p>
                        <p class="text-[14px] font-normal">${helper.tipe_rumah}</p>
                    </div>
                    <div class="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                    <div>
                        <p class="text-body-lg text-white">Penghuni</p>
                        <p class="text-[14px] font-normal">${helper.penghuni}</p>
                    </div>
                    <div class="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                    <div>
                        <p class="text-body-lg text-white">Gaji</p>
                        <p class="text-[14px] font-normal">$ ${helper.gaji}</p>
                    </div>
                    <div class="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                    <div>
                        <p class="text-body-lg text-white">Potongan Gaji</p>
                        <p class="text-[14px] font-normal">$ ${helper.potongan_gaji} </p>
                    </div>
                    <div class="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                    <div>
                        <p class="text-body-lg text-white">Jumlah Libur/Bulan</p>
                        <p class="text-[14px] font-normal">${helper.jumlah_off}</p>
                    </div>
                </div>
                <div class="mt-4">
                    <p class="text-body-lg text-white">Kriteria</p>
                    <p class="text-[14px] font-normal text-[#BFBFBF]">${helper.kriteria}</p>
                </div>
                    <div class="mt-4">
                    <p class="text-body-lg text-white">Kualifikasi Helper</p>
                    <p class="text-[14px] font-normal text-[#BFBFBF]">${helper.kualifikasi_helper}</p>
                </div>
            </div>
        `,
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                popup: 'custom-popup',
            },
            width: '800px',
        });
    };

    const handleSearch = debounce((value) => {
        setPage(0);
        setKeyword(value);
    }, 500);

    const handleChange = (e) => {
        setQuery(e.target.value);
        handleSearch(e.target.value);
    };

    const handleItemsPerPageChange = (e) => {
        setLimit(parseInt(e.target.value));
        setPage(0);
    };

    const changePage = ({ selected }) => {
        setPage(selected);
    };

    return (
        <div className="mt-[70px] p-[32px]">
            <div className="overscroll-contain">
                <h1 className="text-headline-md text-white">Employers</h1>

                <button
                    className="my-4 flex items-center rounded-lg bg-[#FF971D] px-[16px] py-[8px] text-white"
                    onClick={() => navigate('/admin/employer/add')}>
                    <IoAddCircle className="mr-2" size={24} />
                    Tambah Employer
                </button>

                {/* Tabel Helpers */}
                <div className="rounded-lg bg-[#202022] p-4">
                    <span className="text-body-lg text-white">
                        Daftar Employer
                    </span>

                    <div className="my-2 justify-between lg:flex">
                        <select
                            value={limit}
                            onChange={handleItemsPerPageChange}
                            className="rounded-lg border border-[#F3B129] bg-[#202022] p-2 text-white">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                        </select>

                        <div className="flex h-[40px] w-[30%] items-center rounded-lg border border-[#F3B129] bg-[#202022] p-[6px]">
                            <IoSearch
                                className="mx-1 text-gray-400"
                                size={24}
                            />
                            <input
                                type="text"
                                placeholder="Cari..."
                                value={query}
                                onChange={handleChange}
                                className="ml-2 w-full border-none bg-[#202022] text-white placeholder-gray-400 outline-none"
                            />
                        </div>
                    </div>

                    <div className="my-4 overflow-x-auto rounded-lg border border-[#515151]">
                        <table className="table w-full space-y-6 rounded-t-lg p-[2px] text-white">
                            <thead>
                                <tr className="border-b border-[#515151] py-2 text-subtitle-sm text-white">
                                    <th className="w-auto p-3 text-center">
                                        No
                                    </th>
                                    <th className="w-[15%] p-3 text-left">
                                        Nama
                                    </th>
                                    <th className="p-3 text-left">Pekerjaan</th>
                                    <th className="p-3 text-left">Negara</th>
                                    <th className="p-3 text-left">Kriteria</th>
                                    <th className="p-3 text-left">
                                        Kualifikasi Helper
                                    </th>
                                    <th className="w-[15%] p-3 text-white">
                                        Aksi
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employers.length > 0 ? (
                                    employers.map((employer, index) => (
                                        <tr
                                            key={employer.id}
                                            className="border-t border-[#515151] font-light">
                                            <td className="px-4 py-2 text-white">
                                                {(page - 0) * limit + index + 1}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {employer.nama}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {employer.pekerjaan}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {employer.negara}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {employer.kriteria}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {employer.kualifikasi_helper}
                                            </td>
                                            <td className="p-3">
                                                <div className="flex w-fit items-center rounded-md bg-[#313135] px-[16px] py-[8px]">
                                                    <a
                                                        className="mr-2 text-gray-400 hover:text-gray-100"
                                                        title="View"
                                                        onClick={() =>
                                                            handleViewClick(
                                                                employer,
                                                            )
                                                        }>
                                                        <FaEye
                                                            className="text-base text-white"
                                                            size={18}
                                                        />
                                                    </a>
                                                    <div className="mx-[8px] h-[18px] w-[2px] bg-[#979797]"></div>
                                                    <Link
                                                        to={`/admin/employer/edit/${employer.id}`}
                                                        className="mx-2 text-gray-400 hover:text-gray-100">
                                                        <FaRegEdit
                                                            className="text-base text-white"
                                                            size={18}
                                                        />
                                                    </Link>
                                                    <div className="mx-[8px] h-[18px] w-[2px] bg-[#979797]"></div>
                                                    <a
                                                        href={employer.id}
                                                        className="ml-2 text-gray-400 hover:text-gray-100"
                                                        title="Delete"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteClick(
                                                                employer.id,
                                                            );
                                                        }}>
                                                        <FaRegTrashAlt
                                                            className="text-base text-red-600"
                                                            size={18}
                                                        />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="7"
                                            className="px-4 py-2 text-center text-white">
                                            {msg || 'Tidak ada data'}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-[32px] flex items-center justify-between">
                        <div className="text-white">
                            Menampilkan {employers.length} dari {rows} data
                        </div>
                        <ReactPaginate
                            className="flex gap-x-2 rounded-md border border-[#f3b129] p-2"
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            pageCount={pages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={changePage}
                            containerClassName="flex space-x-2"
                            pageClassName="px-4 py-2 rounded-lg cursor-pointer hover:bg-[#F3B129] hover:text-white"
                            previousClassName="px-4 py-2 rounded-lg cursor-pointer"
                            nextClassName="px-4 py-2 rounded-lg cursor-pointer"
                            activeClassName="bg-[#F3B129] text-white"
                            disabledClassName="text-gray-500 cursor-not-allowed"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Employes;
