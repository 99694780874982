import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { baseApi } from '../../utils/constant';

function EditEmployer() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [expired, setExpired] = useState(0);
    const [token, setToken] = useState('');
    // const [file, setFile] = useState('');
    // const [preview, setPreview] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    const [nama, setNama] = useState('');
    const [pekerjaan, setPekerjaan] = useState('');
    const [kriteria, setKriteria] = useState('');
    const [alamat, setAlamat] = useState('');
    const [tipeRumah, setTipeRumah] = useState('');
    const [penghuni, setPenghuni] = useState('');
    const [gaji, setGaji] = useState('');
    const [potonganGaji, setPotonganGaji] = useState('');
    const [jumlahOff, setJumlahOff] = useState('');
    const [kualifikasiHelper, setKualifikasiHelper] = useState('');
    const [negara, setNegara] = useState('');
    const [rasMajikan, setRasMajikan] = useState('');

    // const loadImage = (e) => {
    //     const image = e.target.files[0];
    //     setFile(image);
    //     setPreview(URL.createObjectURL(image));
    // };

    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            await refreshToken();
            await getEmployerById(id);
        };
        fetchData();
    }, [id]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseApi}/users/token`);
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setUsername(decoded.username);
            setEmail(decoded.email);
            setExpired(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate('/login');
            }
        }
    };

    const axiosJwt = axios.create();

    axiosJwt.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expired < currentDate.getTime() / 1000) {
                const response = await axios.get(`${baseApi}/users/token`);
                config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                setToken(response.data.accessToken);
                const decoded = jwtDecode(response.data.accessToken);
                setUsername(decoded.username);
                setEmail(decoded.email);
                setExpired(decoded.exp);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    const getEmployerById = async (id) => {
        const response = await axiosJwt.get(
            `${baseApi}/employer/employers/${id}`,
        );
        setNama(response.data.nama);
        setPekerjaan(response.data.pekerjaan);
        setKriteria(response.data.kriteria);
        setAlamat(response.data.alamat);
        setTipeRumah(response.data.tipe_rumah);
        setPenghuni(response.data.penghuni);
        setGaji(response.data.gaji);
        setPotonganGaji(response.data.potongan_gaji);
        setJumlahOff(response.data.jumlah_off);
        setKualifikasiHelper(response.data.kualifikasi_helper);
        setNegara(response.data.negara);
        setRasMajikan(response.data.ras_majikan);
    };

    const updateEmployer = async (e) => {
        e.preventDefault();

        // Konfirmasi SweetAlert2
        const result = await MySwal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin ingin menyimpan perubahan ini?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya, Simpan',
            cancelButtonText: 'Batal',
            reverseButtons: true,
            customClass: {
                popup: 'custom-swal', // Menambahkan kelas kustom
            },
            background: 'transparent', // Agar background default tidak mengganggu
            iconColor: '#B24400', // Mengubah warna ikon
        });

        if (result.isConfirmed) {
            const formData = new FormData();
            formData.append('nama', nama);
            formData.append('pekerjaan', pekerjaan);
            formData.append('alamat', alamat);
            formData.append('kriteria', kriteria);
            formData.append('tipe_rumah', tipeRumah);
            formData.append('penghuni', penghuni);
            formData.append('gaji', gaji);
            formData.append('potongan_gaji', potonganGaji);
            formData.append('jumlah_off', jumlahOff);
            formData.append('kualifikasi_helper', kualifikasiHelper);
            formData.append('negara', negara);
            formData.append('ras_majikan', rasMajikan);
            // if (file) {
            //     formData.append('foto_profil', file);
            // }

            try {
                await axios.put(
                    `${baseApi}/employer/employers/${id}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );

                // Tampilkan SweetAlert2 Toast ketika berhasil
                MySwal.fire({
                    icon: 'success',
                    title: 'Data berhasil diperbarui!',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    customClass: {
                        popup: 'custom-swal', // Menambahkan kelas kustom
                    },
                    background: 'transparent', // Agar background default tidak mengganggu
                    iconColor: '#409900', // Mengubah warna ikon
                });

                // Arahkan kembali ke halaman admin/helpers
                navigate('/admin/employer');
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data);
                    setMessage(error.response.data.message);

                    // Tampilkan SweetAlert2 Toast untuk kesalahan
                    MySwal.fire({
                        icon: 'error',
                        title: 'Gagal memperbarui data!',
                        text: error.response.data.message,
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    });
                }
            }
        }
    };

    return (
        <div className="mt-[70px] p-[32px]">
            <div className="overscroll-contain">
                <h1 className="text-headline-md text-white">Edit Employer</h1>

                <div className="my-[32px] flex">
                    <Link
                        to="/admin/employer"
                        className="flex items-center gap-[6px] rounded-lg bg-[#4A4A4F] px-[18px] py-[12px] text-button-lg text-white hover:bg-[#515151]">
                        <IoMdArrowRoundBack size={18} className="fill-white" />
                        <caption>Kembali</caption>
                    </Link>
                </div>

                <div className="h-full w-full rounded-lg bg-[#202022] p-[32px]">
                    <p className="mb-[24px] text-headline-md text-white">
                        Form Edit Employer
                    </p>
                    <form action="" onSubmit={updateEmployer}>
                        <label className="block">
                            <span className="block text-subtitle-lg font-semibold text-white">
                                Nama
                            </span>
                            <input
                                className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                type="text"
                                name=""
                                placeholder="Masukkan Nama"
                                id=""
                                value={nama}
                                onChange={(e) => setNama(e.target.value)}
                                required
                            />
                        </label>
                        <label className="mt-4 block">
                            <span className="block text-subtitle-lg font-semibold text-white">
                                Pekerjaan
                            </span>
                            <input
                                className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                type="text"
                                name=""
                                placeholder="Masukkan Pekerjaan"
                                id=""
                                value={pekerjaan}
                                onChange={(e) => setPekerjaan(e.target.value)}
                                required
                            />
                        </label>

                        <div className="flex flex-col lg:flex-row">
                            <label className="mt-4 block w-full">
                                <span className="block text-subtitle-lg font-semibold text-white">
                                    Tipe Rumah
                                </span>
                                <input
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="text"
                                    name=""
                                    placeholder="Masukan Tipe Rumah"
                                    id=""
                                    value={tipeRumah}
                                    onChange={(e) =>
                                        setTipeRumah(e.target.value)
                                    }
                                    required
                                />
                            </label>
                            <label className="mt-4 block w-full lg:ml-4">
                                <span className="block text-subtitle-lg font-semibold text-white">
                                    Penghuni
                                </span>
                                <input
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="text"
                                    name=""
                                    placeholder="Masukan Jumlah Penghuni"
                                    id=""
                                    value={penghuni}
                                    onChange={(e) =>
                                        setPenghuni(e.target.value)
                                    }
                                    required
                                />
                            </label>
                        </div>

                        <div className="flex flex-col lg:flex-row">
                            <label className="mt-4 block w-full">
                                <span className="block text-subtitle-lg font-semibold text-white">
                                    Ras Majikan
                                </span>
                                <input
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="text"
                                    name=""
                                    placeholder="Masukan Ras Majikan"
                                    id=""
                                    value={rasMajikan}
                                    onChange={(e) =>
                                        setRasMajikan(e.target.value)
                                    }
                                    required
                                />
                            </label>
                            <label className="mt-4 block w-full lg:ml-4">
                                <span className="block text-subtitle-lg font-semibold text-white">
                                    Negara
                                </span>
                                <input
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="text"
                                    name=""
                                    placeholder="Masukan Negara"
                                    id=""
                                    value={negara}
                                    onChange={(e) => setNegara(e.target.value)}
                                    required
                                />
                            </label>
                            <label className="mt-4 block w-full lg:ml-4">
                                <span className="block text-subtitle-lg font-semibold text-white">
                                    Alamat
                                </span>
                                <input
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="text"
                                    name=""
                                    placeholder="Masukan Alamat"
                                    id=""
                                    value={alamat}
                                    onChange={(e) => setAlamat(e.target.value)}
                                    required
                                />
                            </label>
                        </div>

                        <div className="flex flex-col lg:mt-4 lg:flex-row">
                            <label className="mt-4 block w-full">
                                <span className="block text-subtitle-lg font-semibold text-white">
                                    Gaji
                                </span>
                                <input
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="text"
                                    name=""
                                    placeholder="Masukan Jumlah Gaji"
                                    id=""
                                    value={gaji}
                                    onChange={(e) => setGaji(e.target.value)}
                                    required
                                />
                            </label>
                            <label className="mt-4 block w-full lg:ml-4">
                                <span className="block text-subtitle-lg font-semibold text-white">
                                    Potongan Gaji
                                </span>
                                <input
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="text"
                                    name=""
                                    placeholder="Masukan Potongan Gaji"
                                    id=""
                                    value={potonganGaji}
                                    onChange={(e) =>
                                        setPotonganGaji(e.target.value)
                                    }
                                    required
                                />
                            </label>
                            <label className="mt-4 block w-full lg:ml-4">
                                <span className="block text-subtitle-lg font-semibold text-white">
                                    Jumlah Off
                                </span>
                                <input
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="text"
                                    name=""
                                    placeholder="Masukan Jumlah Libur"
                                    id=""
                                    value={jumlahOff}
                                    onChange={(e) =>
                                        setJumlahOff(e.target.value)
                                    }
                                    required
                                />
                            </label>
                        </div>

                        <label className="mt-4 block">
                            <span className="block text-subtitle-lg font-semibold text-white">
                                Kriteria
                            </span>
                            <textarea
                                className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                type="text"
                                name=""
                                placeholder="Masukkan Kriteria"
                                id=""
                                value={kriteria}
                                onChange={(e) => setKriteria(e.target.value)}
                                required
                            />
                        </label>

                        <label className="mt-4 block">
                            <span className="block text-subtitle-lg font-semibold text-white">
                                Kualifikasi Helper
                            </span>
                            <textarea
                                className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                type="text"
                                name=""
                                placeholder="Masukkan Kualifikasi"
                                id=""
                                value={kualifikasiHelper}
                                onChange={(e) =>
                                    setKualifikasiHelper(e.target.value)
                                }
                                required
                            />
                        </label>

                        <div className="mt-8 flex justify-end gap-2">
                            <Link
                                to="/admin/employer"
                                className="flex items-center gap-[6px] rounded-lg bg-[#FF2E2E] px-4 py-2 text-button-lg text-white hover:bg-red-700">
                                <caption>Batal</caption>
                            </Link>

                            <button
                                type="submit"
                                className="rounded-lg bg-[#1AA053] px-4 py-2 text-button-lg text-white hover:bg-green-700">
                                Simpan
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditEmployer;
