import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseApi } from '../utils/constant';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
// Icon
import { IoMenu, IoClose } from 'react-icons/io5';
import { IoArrowDownCircleOutline } from 'react-icons/io5';
import Profile from '../assets/howit1.jpg';

function NavbarAdmin({ toggleSidebar, isSidebarOpen }) {
    // const [username, setUsername] = useState('');
    // const [email, setEmail] = useState('');
    // const [token, setToken] = useState('');
    // const [users, setUsers] = useState([]);
    // const navigate = useNavigate();

    // const [expired, setExpired] = useState('');

    // useEffect(() => {
    //     getUsers();
    // });

    // const refreshToken = async () => {
    //     try {
    //         const response = await axios.get(`${baseApi}/users/token`);
    //         setToken(response.data.accessToken);
    //         const decoded = jwtDecode(response.data.accessToken);
    //         console.log(decoded);
    //         setUsername(decoded.username);
    //         setEmail(decoded.email);
    //         setExpired(decoded.exp);
    //     } catch (error) {
    //         if (error.response) {
    //             navigate('/login');
    //         }
    //     }
    // };

    // const axiosJwt = axios.create();

    // axiosJwt.interceptors.request.use(
    //     async (config) => {
    //         const currentDate = new Date();
    //         if (expired < currentDate.getTime() / 1000) {
    //             const response = await axios.get(`${baseApi}/users/token`);
    //             config.headers.Authorization = `Bearer ${response.data.accessToken}`;
    //             setToken(response.data.accessToken);
    //             const decoded = jwtDecode(response.data.accessToken);
    //             setUsername(decoded.username);
    //             setEmail(decoded.email);
    //             setExpired(decoded.exp);
    //         }
    //         return config;
    //     },
    //     (error) => {
    //         return Promise.reject(error);
    //     },
    // );

    // const getUsers = async () => {
    //     try {
    //         const response = await axiosJwt.get(`${baseApi}/users/getUsers`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         setUsers(response.data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    return (
        <div className="border-b border-[#4A4A4F] bg-[#111111]">
            <div className="z-10 flex h-[70px] w-full justify-between bg-[#19191A] pl-[32px] pr-[32px] shadow-cardShadow lg:py-[8px] lg:pl-0 lg:pr-[32px]">
                <div className="content-center justify-center align-middle lg:ml-[55px]">
                    <span className="font-bold uppercase text-[#FF971D]">
                        Supplier <span className="text-white">Helper</span>
                    </span>
                </div>
                <button
                    className="flex items-center lg:hidden"
                    onClick={toggleSidebar}>
                    {isSidebarOpen ? (
                        <IoClose size={24} className="fill-white text-white" />
                    ) : (
                        <IoMenu size={24} className="fill-white text-white" />
                    )}
                </button>
                <div className="hidden lg:block">
                    <button className="flex w-auto items-center rounded-md p-2">
                        <div className="flex">
                            <img
                                src={Profile}
                                alt=""
                                className="h-[40px] w-[40px] rounded-full object-cover"
                            />
                            <div className="ml-[16px] hidden text-left lg:block">
                                <p className="text-subtitle-sm font-bold text-[#FFFFFF]">
                                    Admin
                                </p>
                                <p className="text-caption font-semibold text-[#FF971D]">
                                    admin
                                </p>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default NavbarAdmin;
