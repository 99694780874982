import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { IoAddCircle } from 'react-icons/io5';
import { IoSearch } from 'react-icons/io5';
import { FaFilter, FaRegEdit, FaEye, FaRegTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import debounce from 'lodash.debounce';
import { baseApi } from '../utils/constant';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import { useLocation } from 'react-router-dom';
import fotoProfile from '../assets/default_profile.png';

function Helpers() {
    const [helpers, setHelpers] = useState([]);
    const [page, setPage] = useState();
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [query, setQuery] = useState('');
    const [msg, setMsg] = useState('');
    const [token, setToken] = useState('');
    const [expired, setExpired] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getHelpers();
    }, [page, keyword, limit]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseApi}/users/token`);
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpired(decoded.exp);
        } catch (error) {
            navigate('/login');
        }
    };

    const axiosJwt = axios.create();

    axiosJwt.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expired < currentDate.getTime() / 1000) {
                const response = await axios.get(`${baseApi}/users/token`);
                config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                setToken(response.data.accessToken);
                const decoded = jwtDecode(response.data.accessToken);
                setExpired(decoded.exp);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    const getHelpers = async () => {
        try {
            const response = await axiosJwt.get(
                `${baseApi}/helper/helpersList?search_query=${keyword}&page=${page}&limit=${limit}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            setHelpers(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);

            if (response.data.result.length === 0) {
                setMsg('Data yang anda cari tidak di temukan.');
            } else {
                setMsg('');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setMsg('Terjadi kesalahan saat mengambil data.');
        }
    };

    const handleDeleteClick = (id) => {
        Swal.fire({
            title: 'Apakah Anda yakin?',
            text: 'Data yang dihapus tidak dapat dikembalikan!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ya, hapus!',
            cancelButtonText: 'Batal',
            customClass: {
                popup: 'custom-popup',
            },
            iconColor: '#B24400',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id);
            }
        });
    };

    const handleDelete = async (id) => {
        try {
            await axiosJwt.delete(`${baseApi}/helper/helpers/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            getHelpers();
            Swal.fire({
                icon: 'success',
                title: 'Data berhasil dihapus.',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                customClass: {
                    popup: 'custom-swal',
                },
                background: 'transparent',
                iconColor: '#409900',
            });
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Terjadi kesalahan saat menghapus data.',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                customClass: {
                    popup: 'custom-swal',
                },
            });
        }
    };

    const handleViewClick = (helper) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: '',
            html: (
                <div className="justify-center lg:text-left">
                    <div className="flex flex-col items-center lg:flex-row lg:text-left">
                        <div className="content-center justify-center">
                            <img
                                src={helper.url}
                                alt="Foto Profil"
                                className="h-[110px] w-[110px] rounded-full object-cover"
                            />
                        </div>
                        <div className="ml-0 mt-4 w-[70%] flex-col flex-wrap content-center lg:-mt-0 lg:ml-[16px]">
                            <p className="text-headline-md font-bold uppercase">
                                {helper.nama}
                            </p>
                            <p className="whitespace-normal text-body-base font-light">
                                {helper.no_hp}
                            </p>
                            <p className="whitespace-normal text-body-base font-light">
                                {helper.alamat}, {helper.negara}
                            </p>
                            <p className="whitespace-normal text-body-base font-light">
                                {calculateAge(helper.tanggal_lahir)} Tahun
                            </p>
                        </div>
                    </div>
                    <div class="mt-4 flex flex-col flex-wrap text-center capitalize text-[#BFBFBF] lg:flex-row lg:flex-nowrap lg:justify-between lg:text-left">
                        <div>
                            <p className="text-body-lg text-white">
                                Tanggal Lahir
                            </p>
                            <p className="text-[14px] font-normal">
                                {new Date(
                                    helper.tanggal_lahir,
                                ).toLocaleDateString('id-ID', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                })}
                            </p>
                        </div>
                        <div className="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                        <div>
                            <p className="text-body-lg text-white">Agama</p>
                            <p className="text-[14px] font-normal">
                                {helper.agama}
                            </p>
                        </div>
                        <div className="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                        <div>
                            <p className="text-body-lg text-white">TB/BB</p>
                            <p className="text-[14px] font-normal">
                                {helper.tinggi_badan} Cm / {helper.berat_badan}{' '}
                                Kg
                            </p>
                        </div>
                        <div className="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                        <div>
                            <p className="text-body-lg text-white">
                                Status Pernikahan
                            </p>
                            <p className="text-[14px] font-normal">
                                {helper.status_pernikahan}
                            </p>
                        </div>
                        <div className="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                        <div>
                            <p className="text-body-lg text-white">
                                Pendidikan
                            </p>
                            <p className="text-[14px] font-normal">
                                {helper.pendidikan}
                            </p>
                        </div>
                    </div>
                    <p className="mt-4">Infromasi Selengkapnya</p>
                    <div class="mt-4 flex flex-col flex-wrap text-center capitalize text-[#BFBFBF] lg:flex-row lg:flex-nowrap lg:justify-between lg:text-left">
                        <div>
                            <p className="text-body-lg text-white">
                                Jumlah Saudara
                            </p>
                            <p className="text-[14px] font-normal">
                                {helper.jumlah_saudara}
                            </p>
                        </div>
                        <div className="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                        <div>
                            <p className="text-body-lg text-white">
                                Jumlah Anak
                            </p>
                            <p className="text-[14px] font-normal">
                                {helper.jumlah_anak}
                            </p>
                        </div>
                        <div className="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                        <div>
                            <p className="text-body-lg text-white">Alergi</p>
                            <p className="text-[14px] font-normal">
                                {helper.alergi}
                            </p>
                        </div>
                        <div className="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                        <div>
                            <p className="text-body-lg text-white">Tato</p>
                            <p className="text-[14px] font-normal">
                                {helper.tato}
                            </p>
                        </div>
                    </div>
                    <div class="mt-4 flex flex-col flex-wrap text-center capitalize text-[#BFBFBF] lg:flex-row lg:flex-nowrap lg:justify-between lg:text-left">
                        <div>
                            <p className="text-body-lg text-white">
                                Kemampuan Bahasa
                            </p>
                            <p className="text-[14px] font-normal">
                                {helper.kemampuan_bahasa}
                            </p>
                        </div>
                        <div className="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                        <div>
                            <p className="text-body-lg text-white">
                                Kemampuan Masak
                            </p>
                            <p className="text-[14px] font-normal">
                                {helper.kemampuan_masak}
                            </p>
                        </div>
                    </div>
                    <div className="mt-4">
                        <p className="text-body-lg text-white">Pengalaman</p>
                        <p className="text-[14px] font-normal text-[#BFBFBF]">
                            {helper.pengalaman}
                        </p>
                    </div>
                    <div className="mt-4">
                        <p className="text-body-lg text-white">Deskripsi</p>
                        <p className="text-[14px] font-normal text-[#BFBFBF]">
                            {helper.deskripsi}
                        </p>
                    </div>
                    <div className="mt-4">
                        <p className="text-body-lg text-white">Sponsor</p>
                        <p className="text-[14px] font-normal text-[#BFBFBF]">
                            {helper.sponsor}
                        </p>
                    </div>
                    <div className="mt-4">
                        <p className="text-body-lg text-white">
                            Bandara Terdekat
                        </p>
                        <p className="text-[14px] font-normal text-[#BFBFBF]">
                            {helper.nama_bandara}
                        </p>
                    </div>
                </div>
            ),
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                popup: 'custom-popup',
            },
            width: '800px',
        });
    };

    const handleSearch = debounce((value) => {
        setPage(0);
        setKeyword(value);
    }, 500);

    const handleChange = (e) => {
        setQuery(e.target.value);
        handleSearch(e.target.value);
    };

    const handleSortChange = (e) => {
        // Implement your sorting logic here
    };

    const handleItemsPerPageChange = (e) => {
        setLimit(parseInt(e.target.value));
        setPage(0);
    };

    const changePage = ({ selected }) => {
        setPage(selected);
    };

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birthDateObj = new Date(birthDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDifference = today.getMonth() - birthDateObj.getMonth();

        // Jika bulan sekarang kurang dari bulan lahir atau sama dengan tetapi hari sekarang kurang dari hari lahir, kurangi umur dengan 1
        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
        ) {
            age--;
        }

        return age;
    };

    return (
        <div className="mt-[70px] p-[32px]">
            <div className="overscroll-contain">
                <h1 className="text-headline-md text-white">Helpers</h1>

                <button
                    className="my-4 flex items-center rounded-lg bg-[#FF971D] px-[16px] py-[8px] text-white"
                    onClick={() => navigate('/admin/helpers/add')}>
                    <IoAddCircle className="mr-2" size={24} />
                    Tambah Helper
                </button>

                {/* Tabel Helpers */}
                <div className="rounded-lg bg-[#202022] p-4">
                    <span className="text-body-lg text-white">
                        Daftar Helper
                    </span>

                    <div className="my-2 justify-between lg:flex">
                        <select
                            value={limit}
                            onChange={handleItemsPerPageChange}
                            className="rounded-lg border border-[#F3B129] bg-[#202022] p-2 text-white">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                        </select>

                        <div className="flex h-[40px] w-[30%] items-center rounded-lg border border-[#F3B129] bg-[#202022] p-[6px]">
                            <IoSearch
                                className="mx-1 text-gray-400"
                                size={24}
                            />
                            <input
                                type="text"
                                placeholder="Cari..."
                                value={query}
                                onChange={handleChange}
                                className="ml-2 w-full border-none bg-[#202022] text-white placeholder-gray-400 outline-none"
                            />
                        </div>
                    </div>

                    <div className="my-4 overflow-x-auto rounded-lg border border-[#515151]">
                        <table className="table w-full space-y-6 rounded-t-lg p-[2px] text-white">
                            <thead>
                                <tr className="border-b border-[#515151] py-2 text-subtitle-sm text-white">
                                    <th className="w-auto p-3 text-center">
                                        No
                                    </th>
                                    <th className="w-[15%] p-3 text-left">
                                        Nama
                                    </th>
                                    <th className="w-[15%] p-3 text-left">
                                        Sponsor
                                    </th>
                                    <th className="p-3 text-left">Umur</th>
                                    <th className="w-[5%] p-3 text-left">
                                        TB/BB
                                    </th>
                                    <th className="p-3 text-left">
                                        Kemampuan Bahasa
                                    </th>
                                    <th className="p-3 text-left">
                                        Kemampuan Masak
                                    </th>
                                    <th className="p-2 text-left">
                                        Pengalaman
                                    </th>
                                    <th className="p-3 text-left">Status</th>
                                    <th className="p-3 text-white">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {helpers.length > 0 ? (
                                    helpers.map((helper, index) => (
                                        <tr
                                            key={helper.id}
                                            className="border-t border-[#515151] font-light">
                                            <td className="px-4 py-2 text-white">
                                                {(page - 0) * limit + index + 1}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {helper.nama}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {helper.sponsor}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {calculateAge(
                                                    helper.tanggal_lahir,
                                                )}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {helper.tinggi_badan}/
                                                {helper.berat_badan}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {helper.kemampuan_bahasa}
                                            </td>
                                            <td className="px-4 py-2 text-white">
                                                {helper.kemampuan_masak}
                                            </td>
                                            <td className="max-w-xs break-words px-4 py-2 text-white">
                                                <p>{helper.pengalaman}</p>
                                            </td>
                                            <td className="px-2 py-2 text-center capitalize text-white">
                                                <p
                                                    className={`rounded-full p-1 ${
                                                        helper.status ===
                                                        'available'
                                                            ? 'bg-green-700'
                                                            : helper.status ===
                                                                'unpublished'
                                                              ? 'bg-yellow-500'
                                                              : helper.status ===
                                                                  'sold out'
                                                                ? 'bg-red-500'
                                                                : 'bg-gray-300' // Warna default jika status tidak dikenal
                                                    }`}>
                                                    {helper.status}
                                                </p>
                                            </td>
                                            <td className="p-2">
                                                <div className="flex w-fit flex-col items-center justify-center rounded-md bg-[#313135] px-[16px] py-[8px]">
                                                    <a
                                                        className="text-gray-400 hover:text-gray-100"
                                                        title="View"
                                                        onClick={() =>
                                                            handleViewClick(
                                                                helper,
                                                            )
                                                        }>
                                                        <FaEye
                                                            className="text-base text-white"
                                                            size={18}
                                                        />
                                                    </a>
                                                    <div className="my-[8px] h-[1px] w-[8px] bg-[#979797]"></div>
                                                    <Link
                                                        to={`/admin/helpers/edit/${helper.id}`}
                                                        className="text-gray-400 hover:text-gray-100">
                                                        <FaRegEdit
                                                            className="text-base text-green-600"
                                                            size={18}
                                                        />
                                                    </Link>
                                                    <div className="my-[8px] h-[1px] w-[8px] bg-[#979797]"></div>
                                                    <a
                                                        href={helper.id}
                                                        className="text-gray-400 hover:text-gray-100"
                                                        title="Delete"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteClick(
                                                                helper.id,
                                                            );
                                                        }}>
                                                        <FaRegTrashAlt
                                                            className="text-base text-red-600"
                                                            size={18}
                                                        />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="content-center text-center">
                                        <td
                                            colSpan="8"
                                            className="px-4 py-2 text-center text-white">
                                            {msg || 'Tidak ada data'}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-[32px] flex items-center justify-between">
                        <div className="text-white">
                            Menampilkan {helpers.length} dari {rows} data
                        </div>
                        <ReactPaginate
                            className="flex gap-x-2 rounded-md border border-[#f3b129] p-2"
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            pageCount={pages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={changePage}
                            containerClassName="flex space-x-2"
                            pageClassName="px-4 py-2 rounded-lg cursor-pointer hover:bg-[#F3B129] hover:text-white"
                            previousClassName="px-4 py-2 rounded-lg cursor-pointer"
                            nextClassName="px-4 py-2 rounded-lg cursor-pointer"
                            activeClassName="bg-[#F3B129] text-white"
                            disabledClassName="text-gray-500 cursor-not-allowed"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Helpers;
